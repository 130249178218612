body {
    padding-top: 70px;
}

@media (min-width: @screen-md-min) {
    .navbar {
        display : none;
    }

    body {
        padding-top: 0;
    }
}

footer {
    margin-top : 20px;
}

.panel-top {
    h5 {
        margin-bottom : 0;
    }

    .control-column {
        overflow-y : scroll;
        height : 100%;
    }

    .output-column {
        .btn-group {
            margin-bottom : 8px;
        }
    }
}

.resizer {
    position: relative;

    .resizer-handle {
	    position: absolute;
	    //font-size: 0.1px;
	    display: block;

        z-index: 90;

        // for vertical resize
        width: 100%;
        left: 0;

        cursor : row-resize;
        height : 10px;
        bottom: -10px;
        border-top : 1px solid @input-border;
        background : linear-gradient(to bottom,
              rgba(red(@color-gray), green(@color-gray), blue(@color-gray), 0),
              rgba(red(@color-gray), green(@color-gray), blue(@color-gray), 0.5));
    }
}

.panel-bottom {
}

.form-group {
    .control-label {
        font-size : 14px;
        font-weight : normal;
        text-align: left;
        padding-left: 25px;
    }

    .input-group {
        .array-controls .btn {
            font-family : 'tbfont';
        }

        .btn-include {
            width : 30px;

            &.required {
                border-color : @input-border;
                background-color : @color-gray-lightest;

                // override button effects
                cursor : default;
                outline : none !important;
                box-shadow : none !important;

                &:before {
                    display : block;
                    font-size : 26px;
                    height : 14px;
                    font-weight : bold;

                    content : "*";
                }
            }

            &.btn-primary {
                font-family : tbfont;

                &:before {
                    .kvp(@tbfontglyphmap, checkbox-unchecked);
                    content : @kvp;
                }

                &.toggled {
                    &:before {
                        .kvp(@tbfontglyphmap, solid-checkbox-checked);
                        content : @kvp;
                    }
                }
            }
        }
    }
}

.requestStatus {
    color : #000;
    font-weight : normal;
}

#request-editor {
    position : relative;
    height : 300px;
    margin-bottom : 8px;
}

#response-editor {
    position : relative;
    min-height : 300px;
    margin-bottom : 20px;
}

@docs-font-size-base:          16px;
@docs-font-size-h1:            floor((@docs-font-size-base * 2.6));
@docs-font-size-h2:            floor((@docs-font-size-base * 2.15));
@docs-font-size-h3:            ceil((@docs-font-size-base * 1.7));
@docs-font-size-h4:            ceil((@docs-font-size-base * 1.25));
@docs-font-size-h5:            @docs-font-size-base;
@docs-font-size-h6:            ceil((@docs-font-size-base * 0.85));

.page-doc-viewer-static, .page-doc-viewer-request-group {
    font-size : @docs-font-size-base;

    h1 {
        font-size : @docs-font-size-h1;
    }

    h2 {
        font-size : @docs-font-size-h2;
    }

    h3 {
        font-size : @docs-font-size-h3;
    }

    h4 {
        font-size : @docs-font-size-h4;
    }

    h5 {
        font-size : @docs-font-size-h5;
    }

    h6 {
        font-size : @docs-font-size-h6;
    }

    p {
        line-height : 1.5;
    }
}

.page-doc-viewer-static {
    p, ul, ol {
        max-width : 600px;
        line-height : 1.5;

        li {
            margin-bottom : 5px;
        }
    }
}

.page-doc-viewer-request-group {
    .table-param-option td {
        padding : 3px;
    }

    .description-column {
        max-width : 600px;
    }

    .enum-values-descriptions {
        td {
            vertical-align : top;
            padding : 6px 3px;
        }
    }
}
