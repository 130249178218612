@side-menu-open-width : 250px;
@side-menu-closed-width : 45px;

@import "fonts/tbfont";
@import "fonts/lato";

@import "bootstrap/variables";
@import "bootstrap/bootstrap";

@import "tb-form-control-focus";

@import "type";
@import "forms";
@import "dropdowns";

@import "utility";

@import "components/modals";
@import "components/navbar";

.load-error {
    width                   : 250px;
    margin                  : 40px auto;
}

.side-menu-set-size(@side-menu-open-width, @side-menu-closed-width);

nav.side-menu .side-menu-brand {
    margin : 15px;
    height : 29px;
}

.navbar .navbar-brand {
    margin : 14px 0 10px 14px;
    height : 29px;

    padding : 0;
    width : 200px;
}

@import "apiTest";
