// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
   
.tb-button-variant(@color; @background; @border; @focus-hover-border; @active-color; @active-background) {
  transition: box-shadow .12s linear, border .12s linear, color .12s linear, background-color .12s linear, transform .12s linear;
  color: @color;
  background-color: @background;
  border-color: @border;

  &:focus,
  &.focus {
    color: @color;
    background-color: darken(@background, 10%);
        border-color: @focus-hover-border;
    
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{focus-hover-border}");
    outline : none;
  }
  &:hover {
    color: @color;
    border-color: @focus-hover-border;
    transform : translate(-1px, -1px);
    .box-shadow(~"inset 1px 1px 1px 1px rgba(0,0,0,.075), 1px 1px 8px @{focus-hover-border}");
    outline : none;

    &:focus,
    &.focus {
        background-color: darken(@background, 10%);
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @active-color;
    background-color: @active-background;

    .box-shadow(none);
        
    &:hover,
    &:focus,
    &.focus {
      transform : none;
      color: @active-color;
      background-color: @active-background;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus,
    &:active {
      background-color: @background;
      border-color: @border;
      color : @color;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}
