@local-font-path:               "../fonts/lato/";
@local-font-name:               "lato-regular";
@local-font-name-light:         "lato-light";
@local-font-name-black:         "lato-black";
@local-font-name-bold:          "lato-bold";
@local-font-name-italic:        "lato-italic";
@local-font-name-bold-italic:   "lato-bolditalic";

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-black}.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-bold}.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-bold-italic}.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-italic}.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-light}.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name}.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
