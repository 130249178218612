@menu-item-font-size: 18px;
@menu-item-font-size-child: 14px;
@menu-item-border: 1px;

@menu-box-shadow : inset 0 1px 20px rgba(0, 0, 0, 0.075);
@menu-box-shadow-left-side : inset 10px 1px 20px rgba(0, 0, 0, 0.075);

.side-menu-set-size(@open-width; @closed-width) {
    .content-wrapper {
        @media (min-width: @screen-md-min) {
            margin-left : @open-width;
        }
    }

    nav.side-menu {
        width : @open-width;
    }

    body.menu-closed {
        .content-wrapper {
            margin-left : @closed-width;
        }

        nav.side-menu {
            width : @closed-width;
        }
    }
}

@media print {
    body {
        padding-top: 0;
    }

    .navbar {
        position : relative;
        background-color : #fff;
    }
}

.navbar {
    border-style : solid;
    border-color : @input-border;
    border-width : 0 0 1px 0;

    .box-shadow(@menu-box-shadow);

    z-index: (@zindex-modal-background + 5);

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .container {
            margin : 0;
            margin-left : 10px;
            padding : 0;
        }

        li > a {
            padding-left : 22px;
            padding-right : 0;
        }
    }

    @media (max-width: @screen-xs-min) {
        .navbar-brand {
            padding-left : 0;
        }
    }

    .navbar-nav {
        > li > a {
            font-size : 16px;
            line-height : 23px;
        }

        > .open {
            > .dropdown-menu {
                background-color: @navbar-inverse-bg;
                margin-top : 9px;
                border-radius: 4px;
                padding: 3px 4px;

                > li > a {
                    color: @navbar-inverse-link-color;
                    border-radius: @border-radius-base;
                    padding: 6px 9px;
                    font-size : 14px;

                    /* &:hover, */
                    /* &:focus { */
                    /*     background-color: @link-color; */
                    /* } */
                }
            }
        }
    }

    .navbar-toggle {
        @media print {
            display : none;
        }

        height : (@navbar-height + 3);
        border : none;
        padding : 0 21px;
        margin  : 0 0 0 21px;

        color: @color-base;

        .navbar-toggle-icon {
            font-size: 22px;
        }

        body.side-menu-open &, &:hover, &:active {
            color : #fff !important;
        }
    }

    .dropdown-menu {
        .navbar-form .input-group {
            width : 220px;
        }
    }
}

// copied from bootstrap modals.less modal-backgrop
.navbar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: (@zindex-modal-background - 10);
  background-color: @modal-backdrop-bg;
  .opacity(@modal-backdrop-opacity);
}

nav.side-menu {
    @media print {
        display : none;
    }

    &.in {
        right : 0;
        padding-top: 12px;

        .box-shadow(@menu-box-shadow-left-side);
    }

    background: @navbar-inverse-bg;
    display: block;
    height: 100%;
    overflow: auto;
    position: fixed;
    right: -20em;
    z-index: (@zindex-modal-background - 5);
    top: (@navbar-height + 4);

    width: 20em;

    transition: right 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s;

    border-style : solid;
    border-color : @input-border;
    border-width : 0 0 0 1px;

    .box-shadow(@menu-box-shadow);

    @media (min-width: @screen-md-min) {
        left : 0;
        top : 0;

        border-width : 0 1px 0 0;
    }

    > ul {
        body.menu-closed & {
            display : none;
        }

        > li {
            border-bottom: @menu-item-border solid @color-gray-medium;

            &:first-child {
                border-top: @menu-item-border solid @color-gray-medium;
            }

            font-size: @menu-item-font-size;

            margin-left: 20px;

            // full size mode
            @media (min-width: @screen-md-min) {
                margin : 0 20px 0 5px;
            }

            a {
                display : block;
                height : 100%;
                width : 100%;
                padding: 8px 12px;

                border-left : 5px solid rgba(0, 0, 0, 0);

                color : @color-base;
                white-space : nowrap;

                & + ul {
                    display : none;

                    li {
                        a {
                            padding-left : 22px;
                            font-size : @menu-item-font-size-child;

                            &.selected {
                                font-weight : bold;
                                color : @brand-primary;
                            }
                        }
                    }
                }

                &:hover, &:focus, &:active {
                    text-decoration : none;
                }

                &:hover {
                    background-color : @color-gray-lighter;
                    color : @brand-primary;
                }

                &:focus {
                    color : @brand-primary;
                }
            }

            &.selected {
                > a {
                    border-left : 5px solid @brand-primary;

                    font-weight : bold;
                    color : @brand-primary;

                    & + ul {
                        display : block;
                    }
                }
            }


            .input-group {
                margin-right : 20px;
            }
        }
    }

    .side-menu-brand {
        body.menu-closed & {
            display : none;
        }
    }

    .side-menu-brand, .side-menu-toggle {
        display : none;

        // full size mode
        @media (min-width: @screen-md-min) {
            display : block;
        }
    }

    .side-menu-toggle {
        position : absolute;
        top : 0;
        bottom : 0;
        right : 0;
        width : 20px;

        background-color : rgba(0, 0, 0, 0);

        div {
            position : fixed;
            top : 50%;

            font-size : 12px;
            padding-left : 4px;

            &:before {
                font-family : tbfont;

                .kvp(@tbfontglyphmap, arrow-double-left);
                content : @kvp;

                body.menu-closed & {
                    .kvp(@tbfontglyphmap, arrow-double-right);
                    content : @kvp;
                }
            }
        }

        &:hover {
            background-color : rgba(0, 0, 0, 0.2);
            background: linear-gradient(to right,
              rgba(red(@color-gray), green(@color-gray), blue(@color-gray), 0),
              rgba(red(@color-gray), green(@color-gray), blue(@color-gray), 0.5));
        }
    }
}
