.btn-group, .context-menu {
    .dropdown-toggle {
        &:before {
            font-family : tbfont;

            .kvp(@tbfontglyphmap, menu-down);
            content : @kvp;
            float : right;
        }
    }
}

.dropdown-menu > li > a {
  border-top : 1px solid transparent;
  border-bottom : 1px solid transparent;
}

.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
      border-top : 1px solid black;
      border-bottom : 1px solid black;
  }
}
