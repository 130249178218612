.modal {
    .modal-dialog {
        margin-top : 10%;
    }

    .modal-content {
        overflow : hidden;

        .modal-header {
            background-color : @brand-primary;

            .modal-title {
                color : white;
            }

            .close {
                margin-top : 0;
                color : white;
                opacity : 1;

                &:hover {
                    color : darken(white, 33%);
                }
            }
        }

        .modal-body {
        }

        .modal-footer {
            .btn {
                min-width : 60px;
            }
        }
    }

    .modal-dialog.modal-icon {
        .modal-body {
            display : table;

            .icon {
                display : table-cell;
                vertical-align : middle;
                padding-right : 10px;
                font-size : 40px;

                .tbicon();

                color : @panel-primary-border;
            }

            .content {
                display : table-cell;
                vertical-align : middle;

                font-size : 16px;
                font-weight : bold;
            }
        }

        &.modal-icon-question-circle {
            .icon:before {
                .kvp(@tbfontglyphmap, question-circle);
                content : @kvp;
            }
        }

        &.modal-icon-alert-circle {
            .icon:before {
                .kvp(@tbfontglyphmap, alert-circle);
                content : @kvp;

                color : @alert-danger-border;
            }

            .content {
                color : @alert-danger-border;
            }
        }
    }

    &.modal-multi-action {
        .note {
            padding-top : 4px;
        }

        .row {
            margin-bottom : 10px;
        }

        .modal-body .btn {
            width : 100%;
        }
    }
}
